var dropCookie = true; // false disables the Cookie, allowing you to style the banner
var cookieDuration = 365; // Number of days before the cookie expires, and the banner reappears
var cookieName = 'complianceCookie'; // Name of our cookie
var cookieValue = 'on'; // Value of cookie

export function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = '; expires=' + date.toGMTString();
    } else {
        var expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

export function checkCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

// window.onload = function() {
window.addEventListener('DOMContentLoaded', (event) => {
    if (checkCookie(cookieName) != cookieValue) {
        $('.cookie-bar').show();
        $('.cookie-bar button').click(function() {
            createCookie(cookieName, cookieValue, cookieDuration); // Create the cookie
            $('.cookie-bar').hide(300, function() {
                $('.cookie-bar').remove();
            });
        });
    }
});

Object.assign(window, { createCookie, checkCookie });
