export function initSubscriptionForm() {

    const forms = [].slice.apply(document.querySelectorAll('.emailSubscriptionForm'))

    forms.forEach(function(form) {

        const emailInput = form.querySelector('input[name="email"]'),
              agreementBox = form.querySelector('input[name="agreement"]'),
              messageBox = form.querySelector('.-message'),
              messageTextEl = messageBox.querySelector('.-text')

        let locked = false

        emailInput.addEventListener('keyup', event => {
            emailInput.setAttribute('value', emailInput.value.toString())
        })
        form.addEventListener('submit', event => {
            event.preventDefault()
            !locked && checkAgreement && submit()
        })

        function submit() {
            lock()

            const formData = new FormData()
            formData.append('email', emailInput.value)
            formData.append('action', 'emailSubscription/subscription/subscribe')

            if (window.fetch) {
                window.fetch('/', {
                    method: 'POST',
                    body: formData
                }).then(function(response) {
                    response.json().then(onSuccess).catch(onFailure)
                }).catch(onFailure)
            } else if (window.jQuery) {
                jQuery.ajax('/', {
                    method: 'POST',
                    data: formData
                }).done(onSuccess).fail(onFailure)
            } else {
                console.warn('Exausted ajax options')
                onFailure()
            }
        }

        function onSuccess(data) {
            console.log(data)
            if (!data.status || data.status === 'failure') {
                return onFailure(data)
            }
            messageBox.classList.remove('is--hidden')
            messageBox.classList.remove('is--failure')
            messageBox.classList.add('is--success')
            messageTextEl.innerHTML = data.text
            unlock()
        }

        function onFailure(error) {
            messageBox.classList.remove('is--hidden')
            messageBox.classList.remove('is--success')
            messageBox.classList.add('is--failure')
            messageTextEl.innerHTML = "Något gick fel, misslyckades att prenumerera"
            console.warn(error)
            unlock()
        }

        function lock() {
            locked = true
            form.classList.add('is--sending')
        }

        function unlock() {
            locked = false
            form.classList.remove('is--sending')
        }

        function checkAgreement() {
            if (agreementBox.checked) {
                form.classList.add('agreement--accepted')
                return true
            } else {
                form.classList.remove('agreement--accepted')
                return false
            }
        }
    })
}
