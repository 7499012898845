const $ = jQuery

export function initQuiz() {

    const $quiz = $('.quiz')

    if (!$quiz.length) {
        return
    }

    const $form = $quiz.find('.quiz-form'),
          $submitButton = $quiz.find('input[type=submit]'),
          $successElem = $('#success'),
          $errorElem = $('#error')

    $form.on('submit', function(e) {
        e.preventDefault()

        const formFields = $form.serializeArray(),
              data = []

        $submitButton.prop('disabled', true)
        $errorElem.hide()

        if (Object.keys(formFields).length > 0) {
            for (let fieldName of Object.keys(formFields)) {
                const field = formFields[fieldName]
                const input = $form.find('[name=' + field.name + ']')

                const object = {
                    name: field.name,
                    value: field.value,
                }

                if (input.length > 0 && input.data('title')) {
                    object.title = input.data('title')
                }

                data.push(object)
            }
        }

        $.ajax({
            type: 'POST',
            url: '/marholmen-quiz/',
            data: {
                data: JSON.stringify(data),
            },
            dataType: 'json',
            success: function(res) {
                if (!res.success) {
                    console.warn(res)
                    $errorElem.find('p').html('Fel: ' + res.error)
                    $errorElem.show()
                    return false
                }
                $form.slideUp(300)
                $successElem.slideDown(300)
            },
        })
    })
}
