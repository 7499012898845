import './js/cookie';
import './js/price-handler';
import { initQuiz } from './js/marholmen-quiz';
import { initSubscriptionForm } from './js/subscription-form';
import { initYoutube } from './js/youtube';
import { App } from './js/app';


$(() => {
    initYoutube();
    initQuiz();
    initSubscriptionForm();
    App.init();
})

$(window).load(() => {
    $(document).foundation();
});
