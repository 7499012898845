export function initYoutube() {
    const playerId = 'hero-video-iframe';
    const playerElem = document.getElementById(playerId);

    if (playerElem) {
        var playerWrapper;
        var hero;
        var tag = document.createElement('script');
        var firstScriptTag = document.getElementsByTagName('script')[0];

        tag.src = 'https://www.youtube.com/iframe_api';
        tag.id = 'youtube-iframe-api';
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
            hero = document.querySelector('.hero-video-startpage');
            playerWrapper = document.getElementById('hero-video');

            var videoId = playerElem.getAttribute('data-src');

            console.log(playerId, videoId);
            window.player = new window.YT.Player(playerId, {
                height: '500',
                width: '100%',
                videoId,
                playerVars: {
                    autoplay: '1',
                    controls: '0',
                    rel: '0',
                    showinfo: '0',
                    modestbranding: '1',
                    mute: '1'
                },
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange
                }
            });
        };

        function onPlayerReady(event) {
            setVideoSize();
            event.target.mute();
            event.target.playVideo();

            window.onresize = setVideoSize;
        }

        function setVideoSize() {
            var windowWidth = window.innerWidth;
            var heroHeight = hero.clientHeight;
            var wrapperHeight = hero.clientHeight;
            var moveDistance;

            playerWrapper.style.height = windowWidth + 'px';
            moveDistance = (heroHeight - windowWidth) / 2;

            playerElem.style.zIndex = 0;
            playerWrapper.style.backgroundColor = '#000';
            playerWrapper.style.transform = 'translateY(' + moveDistance + 'px)';
        }

        function onPlayerStateChange(event) {
            if (event.data === window.YT.PlayerState.ENDED) {
                window.player.playVideo();
            }
        }
    }
}
