import './vendor/accounting'

window.PriceHandler = function(priceData, localeData) {
    let basePrice = 0
    let totalPrice = 0
    let detailPrices = priceData.detailsPrices
    let days = priceData.days
    let nights = days - 1
    let people = priceData.people
    let pricesAttribueSchema = new AttributeSchema(priceData.prices)
    if (priceData.total_price) {
        basePrice = priceData.total_price
        totalPrice = priceData.total_price
    }
    let $container = priceData.container
    let $cartData = $container.find('.cart-data')
    
    this.countPrices = function() {
        $cartData.empty()
        let formArray = $container.closest('form').serializeArray()
        let start_date = 0
        let end_date = 0
        let pricePerDayString = ''
        let pricePerDay = null
        totalPrice = 0
        basePrice = 0
        
        
        for (let i = 0; i < formArray.length; i++) {
            let name = formArray[i]['name'].replace('[]', '')
            if (name === 'cart[start_date]') {
                start_date = formArray[i]['value']
            } else if (name === 'cart[end_date]') {
                end_date = formArray[i]['value']
            } else if (name === 'cart[people]') {
                people = parseInt(formArray[i]['value'])
            }
        }
        if (priceData.countNights) {
            pricePerDayString = ''
            pricePerDay = pricesAttribueSchema.getValuesBetween(start_date, end_date)
            days = pricePerDay.length
            nights = days - 1
            for (let i = 0; i < pricePerDay.length; i++) {
                pricePerDayString += pricePerDay[i].date + ' - ' + accounting.formatMoney(pricePerDay[i].value) + '<br/>'
                basePrice += parseFloat(pricePerDay[i].value)
            }
        } else {
            basePrice = parseInt(pricesAttribueSchema.getHighestValuesBetween(start_date, end_date))
        }
        
        totalPrice += basePrice
        
        for (let i = 0; i < formArray.length; i++) {
            let name = formArray[i]['name'].replace('[]', '')
            if (detailPrices[name] && detailPrices[name][formArray[i]['value']]) {
                let detailsInput = priceData.detailsPrices[name][formArray[i]['value']]
                let formValue = 0
                for (let d = 0; d < detailsInput.details.length; d++) {
                    let detail = detailsInput.details[d]
                    let price = detail.price
                    let dPrice = 0
                    let type = detail.type
                    switch (type) {
                        case 'Per use':
                        case 'Per stay':
                            dPrice += price
                            break
                        case 'Per night':
                            dPrice += price * nights
                            break
                        case 'Per person':
                            dPrice += price * people
                            break
                        case 'Per person per night':
                            dPrice += price * nights * people
                            break
                    }
                    totalPrice += dPrice
                    formValue += dPrice
                    if (detailsInput.value === '{price}') {
                        formArray[i]['newValue'] = accounting.formatMoney(dPrice)
                    }
                }
                $('<input>').attr({
                    type: 'hidden',
                    name: 'cartdata[' + detailsInput.name + ']',
                    value: (formArray[i]['newValue'] ? formArray[i]['newValue'] : detailsInput.value),
                }).appendTo($cartData)
            }
        }
        if (priceData.countNights) {
            return [totalPrice, pricePerDay, pricePerDayString]
        } else {
            return totalPrice
        }
    }
    
    this.formatMoney = function(money) {
        return accounting.formatMoney(money, localeData.currency_symbol, 2, ' ', localeData.number_symbol, localeData.accounting_format).toString()
          .replace(localeData.number_symbol + '00\u00A0' + localeData.currency_symbol, '\u00A0kr')
    }
    
    this.setNewPrice = function() {
        let newPrices = [],
            newPrice = null
        if (priceData.countNights) {
            newPrices = this.countPrices()
            newPrice = newPrices[0]
        } else {
            newPrice = this.countPrices()
        }
        let newCurrencyPrice = this.formatMoney(newPrice)
        $container.find('.pricefield').html(newCurrencyPrice)
        
        if (priceData.countNights) {
            let $tooltip = $container.find('.price-per-night')
            if (Foundation.libs.tooltip.scope) {
                Foundation.libs.tooltip.getTip($tooltip).html(newPrices[2])
            }
            $container.find('.night-length')
              .html('för ' + newPrices[1].length + ' ' + (newPrices[1].length === 1 ? 'natt' : 'nätter'))
        }
        $container.find('.priceData').val(newPrice)
    }
    
    return this
}