import { DatepickerFunctions } from './datepicker';

window.parsedDateFormat = DatepickerFunctions.parseFormat('yyyy-mm-dd');

export const App = {
    initSelectBox: function() {
        $('select').selectBoxIt({
            autoWidth: false,
            downArrowIcon: 'icon-arrow_down'
        });
    },
    initMenu: function() {
        $('#menu-expanded-button').click(function() {
            $(this).toggleClass('on');
            $('.menu-expanded-container').slideToggle();
        });
    },
    initCheckoutAjax: function() {
        $('.show-more-info-product button').on('click', function(e) {
            //alert('hej');
            //console.log($(this).val());
            //console.log($('input[name="availability[start_date]"]').val());
            $.post(
                '/checkout/ajax_checkout_selection?object_id=' + $(this).val(),
                {
                    start_date: $('input[name="availability[start_date]"]').val(),
                    end_date: $('input[name="availability[end_date]"]').val()
                },
                function(data) {
                    $('.checkout-row-wrapper')
                        .find('.small-12 div')
                        .html(data);
                    //console.log(data);
                }
            );

            e.preventDefault();
        });
    },
    initReviewsCarousel: function() {
        $('.reviews-wrapper').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="icon-arrow_left slick-prev">Previous</button>',
            nextArrow: '<button type="button" class="icon-arrow_right slick-next">Previous</button>',
            arrows: true,
            autoplay: true,
            autoplaySpeed: 8000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    },
    initCarousel: function() {
        $('#slick ul')
            .first()
            .attr('id', 'main-carousel');
        $('.image-carousel-thumbs').each(function() {
            var $this = $(this)
                .first()
                .attr('id', 'sub-carousel');

            var $thumbs = $this.find('a');

            $this.slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                asNavFor: '#main-carousel',
                dots: false,
                arrows: true,
                // centerMode: true,
                focusOnSelect: true,
                prevArrow: '<button type="button" class="icon-arrow_left slick-prev">Previous</button>',
                nextArrow: '<button type="button" class="icon-arrow_right slick-next">Previous</button>'
            });
        });

        $('#main-carousel')
            .first()
            .slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.image-carousel-thumbs',
                prevArrow: '<button type="button" class="icon-arrow_left slick-prev">Previous</button>',
                nextArrow: '<button type="button" class="icon-arrow_right slick-next">Previous</button>',
                arrows: true,
                autoplay: false,
                autoplaySpeed: 4000
            });
    },
    initCalendar: function() {
        var template = [
            '<div class="calendar-container datepicker-content">',
            '<div class="date-info">',
            '<div class="day-name" data-type="day today"></div>',
            '<div class="date">',
            '<span class="month ui-datepicker-month" data-type="month today"></span>',
            '<span class="day" data-type="date today"></span>',
            '</div>',
            '<div class="year" data-type="year today"></div>',
            '</div>',
            '<div class="" data-type="datepicker">',
            '<div class="month-picker">',
            '<div class="datepicker-prev prev-month">',
            '<i class="icon-arrow_left"  data-type="month prev"></i>',
            '</div>',
            '<div class="current-month" data-type="month current"></div>',
            '<div class="datepicker-next next-month">',
            '<i class="icon-arrow_right"  data-type="month next"></i>',
            '</div>',
            '</div>',
            '</div>',
            '<div class="keep-ratio ratio-cal">',
            '<div class="ratio-content days content-days" data-type="days picker">',
            '<ul class="datepicker-week day-short text-center" data-type="week"></ul>',
            '<ul class="datepicker-days" data-type="days"></ul>',
            '</div>',
            '</div>',
            '</div>'
        ].join('');

        //if(!$('.deactivate-datepicker').is(':visible')) {
        $('.input-datepicker').each(function(index, item) {
            //console.log('datepicker',index,item,this);
            var $datepicker = $(item);
            var id = $datepicker.attr('id') || randomString(5);
            var setDays = $datepicker.data('forced-days') ? $datepicker.data('forced-days') : 0;
            var nights = 1;
            var $datepickerInput = $datepicker.find('.datepicker');
            var $datepickerInputStart = $datepicker.find('.datepicker-start');
            var $datepickerInputEnd = $datepicker.find('.datepicker-end');

            if (setDays > 1) {
                nights = setDays - 1;
            }

            if (setDays !== 1) {
                $datepickerInput.attr('name', '');
                $datepickerInput.val($datepickerInputStart.val());
            } else {
                $datepickerInputStart.attr('name', '');
                $datepickerInputEnd.attr('name', '');
            }

            $datepicker.attr('id', id);

            var available = $datepicker.data('available');
            var availableDays = $datepicker.data('available-days');
            var availableFromData = $datepicker.data('available-from');
            var availableFrom = availableFromData ? parseDate(availableFromData, parsedDateFormat) : false;
            var availableToData = $datepicker.data('available-to');
            var availableTo = availableToData ? parseDate(availableToData, parsedDateFormat) : false;
            var dependingOnFromSelector = $datepicker.data('depending-on-from');
            var dependingOnToSelector = $datepicker.data('depending-on-to');
            var possibleStartDays = $datepicker.data('possible-start-days');
            var availableAttribueSchema = new AttributeSchema(available);
            var state = 'Init';

            var initFromDate = parseDate($datepickerInputStart.val(), parsedDateFormat);
            var initToDate = parseDate($datepickerInputEnd.val(), parsedDateFormat);

            if (setDays > 0) {
                initToDate = new Date(initToDate.setDate(initFromDate.getDate() + setDays - 1));
            }

            getFirstDateValues(initFromDate, initToDate);
            //$datepickerInput.fromDate = $datepickerInputStart.val();
            //$datepickerInput.toDate = $datepickerInputEnd.val();

            $(dependingOnFromSelector).change(function(e) {
                var newDate = parseDate($(this).val(), parsedDateFormat);

                //console.log($datepicker,newDate);
                //$datepicker.date

                var datepicker = $datepickerInput.data('datepicker');

                if (datepicker.date < newDate) {
                    setSelectedDate(newDate);
                    datepicker.date = newDate.addDays(Math.min(setDays, 1));
                    changeDateValue();
                }
                //$datepickerInput.toDate
                //console.log($(this).val(),e);
            });

            function changeDateValue() {
                var datepicker = $datepickerInput.data('datepicker');
                var date = datepicker.date;
                var toDate = setSelectedDate(date);
                var __ret = getFirstDateValues(toDate, date);
                var firstDate = __ret.firstDate;
                var secondDate = __ret.secondDate;

                if (!$datepickerInput.oldDate || setDays > 0) {
                    $datepickerInputStart.val(firstDate);
                    $datepickerInputEnd.val(secondDate);

                    if (setDays === 1) {
                        $datepickerInput.val(firstDate);
                    } else {
                        $datepickerInput.val(firstDate + ' - ' + secondDate);
                    }

                    $datepickerInput.trigger('change');
                    $datepickerInputStart.trigger('change');
                    $datepickerInputEnd.trigger('change');
                    datepicker.hide();
                    state = 'Selected';
                } else {
                    $datepickerInputStart.val('');
                    $datepickerInputEnd.val('');
                    $datepickerInput.val('');
                    state = 'Selecting';
                }
            }

            $datepickerInput.datepicker({
                weekStart: 1,
                daysMin: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
                days: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
                months: [
                    'Januari',
                    'Februari',
                    'Mars',
                    'April',
                    'Maj',
                    'Juni',
                    'Juli',
                    'Augusti',
                    'September',
                    'Oktober',
                    'November',
                    'December'
                ],
                monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                template: template,
                dateFormat: 'yyyy-mm-dd',
                itemTag: 'li',
                //'available': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                //'notAvailable': [12, 13, 14],
                isAvailable: function(date) {
                    if (availableFrom && date < availableFrom) {
                        return false;
                    }
                    if (availableTo && date > availableTo) {
                        return false;
                    }
                    if (!available) {
                        return false;
                    }

                    if (
                        possibleStartDays &&
                        possibleStartDays.length &&
                        possibleStartDays.indexOf(date.getDay().toString()) === -1
                    ) {
                        return false;
                    }

                    var datepicker = $datepickerInput.data('datepicker');

                    if (datepicker && state === 'Selecting') {
                        if (!availableAttribueSchema.isDatesConnected(datepicker.date, date, nights)) {
                            return false;
                        }

                        return true;
                    }

                    var closestDate = availableAttribueSchema.findClosestDate(date.addDays(nights - 1));

                    if (!closestDate) {
                        return false;
                    }

                    var formatedDate = DatepickerFunctions.formatDate(closestDate, parsedDateFormat);

                    if (available && availableAttribueSchema.datesFormated.hasOwnProperty(formatedDate)) {
                        return availableAttribueSchema.datesFormated[formatedDate] >= 1;
                    }
                    return false;
                },
                isSelected: function(date) {
                    if (
                        !$datepickerInput.oldDate &&
                        $datepickerInput.fromDate <= date &&
                        date <= $datepickerInput.toDate
                    ) {
                        return true;
                    }
                    return false;
                },
                isNotAvailable: function(date) {
                    if (!available) {
                        return false;
                    }

                    var datepicker = $datepickerInput.data('datepicker');

                    if (datepicker && state === 'Selecting') {
                        if (!availableAttribueSchema.isDatesConnected(datepicker.date, date)) {
                            return false;
                        }
                    }

                    var closestDate = availableAttribueSchema.findClosestDate(date);

                    if (!closestDate || closestDate.getTime() === date.getTime()) {
                        return false;
                    }

                    var formatedDate = DatepickerFunctions.formatDate(closestDate, parsedDateFormat);

                    if (availableAttribueSchema.datesFormated.hasOwnProperty(formatedDate)) {
                        return availableAttribueSchema.datesFormated[formatedDate] < 1;
                    }
                    return false;
                },
                isDisabled: function(date) {
                    if (
                        possibleStartDays &&
                        possibleStartDays.length &&
                        possibleStartDays.indexOf(date.getDay().toString()) === -1
                    ) {
                        return true;
                    }

                    if (dependingOnFromSelector) {
                        var dependingOnFromVal = $(dependingOnFromSelector).val();

                        if (dependingOnFromVal) {
                            var dependingOnFrom = parseDate(dependingOnFromVal, parsedDateFormat);

                            if (dependingOnFrom && date < dependingOnFrom) {
                                return true;
                            }
                        }
                    }
                    if (dependingOnToSelector) {
                        var dependingOnToVal = $(dependingOnToSelector).val();

                        if (dependingOnToVal) {
                            var dependingOnTo = parseDate(dependingOnToVal, parsedDateFormat);

                            if (dependingOnTo && date > dependingOnTo) {
                                return true;
                            }
                        }
                    }
                    if (availableFrom && date < availableFrom) {
                        return true;
                    }
                    if (availableTo && date > availableTo) {
                        return true;
                    }
                    if (!available) {
                        return false;
                    }

                    var datepicker = $datepickerInput.data('datepicker');

                    if (datepicker && state === 'Selecting') {
                        if (!availableAttribueSchema.isDatesConnected(datepicker.date, date, 1)) {
                            return true;
                        }

                        return false;
                    }

                    var closestDate = availableAttribueSchema.findClosestDate(date.addDays(nights - 1));

                    if (!closestDate) {
                        return true;
                    }
                    var formatedDate = DatepickerFunctions.formatDate(closestDate, parsedDateFormat);

                    if (availableAttribueSchema.datesFormated.hasOwnProperty(formatedDate)) {
                        return availableAttribueSchema.datesFormated[formatedDate] < 1;
                    }
                    return false;
                },
                onChange: function(date) {
                    changeDateValue();
                    return false;
                },
                alwaysVisible:
                    typeof $datepicker.data('always-visible') !== 'undefined'
                        ? $datepicker.data('always-visible')
                        : false,
                trigger: '#' + id + ' .icon-calendar, #' + id + ' .datepicker'
            });

            if ($datepickerInputStart.val() && $datepickerInputEnd.val()) {
                setSelectedDate(
                    parseDate($datepickerInputStart.val(), parsedDateFormat),
                    parseDate($datepickerInputEnd.val(), parsedDateFormat)
                );
            } else if ($datepickerInput.val()) {
                setSelectedDate(parseDate($datepickerInput.val(), parsedDateFormat));
            }

            $(document).on('mouseenter mouseleave', '#' + id + ' .calendar-container .datepicker-days li', function(e) {
                $('.calendar-container .datepicker-days li span').removeClass('hover-select');
                if (e.type === 'mouseleave') {
                    return;
                }
                //$(this).nextAll().andSelf().slice(0, setDays).find('span').addClass('hover-select');
                var datepicker = $datepickerInput.data('datepicker');

                if (setDays > 0) {
                    $(this)
                        .nextAll()
                        .andSelf()
                        .slice(0, setDays)
                        .find('span')
                        .addClass('hover-select');
                } else if ($datepickerInput.oldDate) {
                    if (
                        $datepickerInput.oldDate.getMonth() === datepicker.viewDate.getMonth() &&
                        $datepickerInput.oldDate.getYear() === datepicker.viewDate.getYear()
                    ) {
                        if ($(this).isBefore('#' + id + ' .datepicker-days li.selected')) {
                            $datepicker
                                .find('.datepicker-days li.selected')
                                .prevUntil(this)
                                .andSelf()
                                .add(this)
                                .find('span')
                                .addClass('hover-select');
                        } else if ($(this).isAfter('#' + id + ' .datepicker-days li.selected')) {
                            $datepicker
                                .find('.datepicker-days li.selected')
                                .nextUntil(this)
                                .andSelf()
                                .add(this)
                                .find('span')
                                .addClass('hover-select');
                        } else {
                            $(this)
                                .find('span')
                                .addClass('hover-select');
                        }
                    } else if (datepicker.viewDate > $datepickerInput.oldDate) {
                        $datepicker
                            .find('.datepicker-days li:first-child')
                            .nextUntil(this)
                            .andSelf()
                            .add(this)
                            .find('span')
                            .addClass('hover-select');
                    } else if (datepicker.viewDate < $datepickerInput.oldDate) {
                        $datepicker
                            .find('.datepicker-days li:last-child')
                            .prevUntil(this)
                            .andSelf()
                            .add(this)
                            .find('span')
                            .addClass('hover-select');
                    }
                }
            });

            $datepickerInput.oldDate = false;
            $datepickerInput.hasSelected = false;

            function setSelectedDate(date, oldDate) {
                //console.log('setSelected',date,oldDate);
                $datepicker.find('.datepicker-days .selected-dates').removeClass('selected-dates');
                if (oldDate) {
                    $datepickerInput.oldDate = oldDate;
                }
                var toDate = new Date(date.getTime());
                var lis;

                if (setDays > 0) {
                    $datepicker
                        .find('.datepicker-days li.selected')
                        .nextAll()
                        .andSelf()
                        .slice(0, setDays)
                        .find('span')
                        .addClass('selected-dates');
                    toDate.setDate(date.getDate() + setDays - 1);
                } else if ($datepickerInput.oldDate) {
                    var dateCount =
                        Math.round(
                            Math.abs((date.getTime() - $datepickerInput.oldDate.getTime()) / (24 * 60 * 60 * 1000))
                        ) + 1;

                    if (date > $datepickerInput.oldDate) {
                        lis = $datepicker
                            .find('.datepicker-days li.selected')
                            .prevAll()
                            .andSelf();

                        lis.slice(Math.max(0, lis.length - dateCount), lis.length)
                            .find('span')
                            .addClass('selected-dates');
                    } else {
                        lis = $datepicker
                            .find('.datepicker-days li.selected')
                            .nextAll()
                            .andSelf();

                        lis.slice(0, Math.min(dateCount, lis.length))
                            .find('span')
                            .addClass('selected-dates');
                    }

                    toDate = $datepickerInput.oldDate;
                    $datepickerInput.oldDate = false;
                    $datepickerInput.hasSelected = true;
                } else {
                    $datepicker.find('.datepicker-days .selected-dates').removeClass('selected-dates');
                    $datepickerInput.oldDate = date;
                }
                toDate.setHours(0, 0, 0, 0);
                return toDate;
            }

            function getFirstDateValues(toDate, date) {
                var firstDate = DatepickerFunctions.formatDate(toDate, parsedDateFormat);
                var secondDate = DatepickerFunctions.formatDate(date, parsedDateFormat);

                $datepickerInput.fromDate = toDate;
                $datepickerInput.toDate = date;

                if (toDate > date) {
                    firstDate = DatepickerFunctions.formatDate(date, parsedDateFormat);
                    secondDate = DatepickerFunctions.formatDate(toDate, parsedDateFormat);
                    $datepickerInput.fromDate = date;
                    $datepickerInput.toDate = toDate;
                }
                return {
                    firstDate: firstDate,
                    secondDate: secondDate
                };
            }
        });
        //}
    },
    initMobileShoppingCart: function() {
        $('.mobile-menu .icon.icon-basket_empty').on('click', function() {
            if (!$('.cart-mobile-wrapper').length) {
                $('.main-navigation-top').after('<div class="cart-mobile-wrapper"></div>');
            }

            if ($('.cart-mobile-wrapper .icon-close').is(':visible')) {
                $('.receipt-wrapper-mobile').slideUp('slow', function() {
                    $('.mobile-grey-overlay').fadeOut('slow');
                });
                return;
            }

            $.post('/ajax/shopping_cart', function(data) {
                $('.cart-mobile-wrapper')
                    .html(data)
                    .end()
                    .find('.hide-for-small-only')
                    .removeClass('hide-for-small-only', function() {
                        $('.receipt-wrapper-mobile').slideDown('slow', function() {
                            var $mobileGreyOverlay = $('.mobile-grey-overlay');

                            $mobileGreyOverlay.css('height', $(document).height());
                            $mobileGreyOverlay.fadeIn('slow');
                        });
                    });
            });
        });

        $(document).on('click', '.receipt-wrapper-mobile .icon.icon-close, .mobile-grey-overlay', function() {
            if ($('.receipt-button-container').is(':visible')) {
                $('.receipt-wrapper-mobile').slideUp('slow', function() {
                    $('.mobile-grey-overlay').fadeOut('slow');
                });
                return;
            }
        });
    },
    initMobile: function() {
        $('.mobile-show-signup-form').on('click', function(e) {
            $(this)
                .closest('.row')
                .next('.mobile-signup-container')
                .slideToggle();
            e.preventDefault();
        });
        $('.mobile-show-filter').on('click', function() {
            window.scrollTo(0, 0);

            $('.filter-bar-wrapper').show();
        });
        $('.filter-sidebar h2 span').on('click', function() {
            $('.filter-bar-wrapper').hide();
        });
    },
    initInfoBox: function() {
        //console.log($('.product-infobox').offset().top, $('.product-infobox').outerHeight() );
        var $productInfoBox = $('.product-infobox');
        var sticky_from_here = 0;

        if ($productInfoBox && $productInfoBox.length > 0) {
            sticky_from_here = $productInfoBox.offset().top + $productInfoBox.outerHeight() - 66;
            $(window).scroll(check_if_fixed);
            check_if_fixed();
        }

        function check_if_fixed() {
            var $cache = $('.product-infobox');
            var $stickyInfobox = $('.product-infobox-sticky').parent('.hidden');
            var offsetRight = $(window).width() - ($cache.offset().left + $cache.outerWidth());

            if ($(window).scrollTop() > sticky_from_here) {
                $stickyInfobox.css({
                    position: 'fixed',
                    //'top': '10px',
                    display: 'inline',
                    'z-index': 1000,
                    top: '69px',
                    right: offsetRight,
                    'min-width': $cache.outerWidth()
                });
            } else {
                $stickyInfobox.css({
                    display: 'none'
                });
            }

            $cache.css({
                //'position': 'relative',
                //'top': 'auto'
            });
        }
    },
    initSlider: function() {
        $('.slider').each(function(index, item) {
            var $slider = $(item);
            var id = $slider.attr('id') || randomString(5);
            var $sliderInput = $slider.find('.slider-input');
            var $sliderInputStart = $slider.find('.slider-input-start');
            var $sliderInputEnd = $slider.find('.slider-input-end');
            var $sliderRange = $slider.find('.slider-range');
            var unit = $slider.data('unit');
            var currentValue = $slider.data('value');

            if (typeof currentValue !== 'undefined') {
                currentValue = $slider.data('value').split(' ')[0];
            } else {
                currentValue = $sliderInputStart.val();
            }

            var range = $slider.data('range');

            if (range === 0) {
                $sliderInputStart.attr('name', '');
                $sliderInputEnd.attr('name', '');
            } else {
                $sliderInput.attr('name', '');
            }

            $sliderRange.slider({
                range: range !== 0,
                min: parseFloat($slider.data('min')),
                max: parseFloat($slider.data('max')),
                values: range !== 0 ? [$sliderInputStart.val(), $sliderInputEnd.val()] : [currentValue],

                create: function(event, ui) {
                    ui.value = $sliderInputStart.val();
                },

                slide: function(event, ui) {
                    if (range !== 0) {
                        $sliderInput
                            .val(ui.values[0] + ' ' + unit + ' - ' + ui.values[1] + ' ' + unit + '')
                            .trigger('change');
                    } else {
                        $sliderInput.val(ui.values[0] + ' ' + unit).trigger('change');
                    }
                    $sliderInputStart.val(ui.values[0]).trigger('change');
                    if (range !== 0) {
                        $sliderInputEnd.val(ui.values[1]).trigger('change');
                    }
                }
            });
            //$sliderInput.val($sliderRange.slider("values", 0) +
            //    " " + unit + " - " + $sliderRange.slider("values", 1) + " " + unit + "");
        });
    },
    initFilterForm: function() {
        var triggerFormChange = 0;

        $('.filter-wrapper form').each(function(index, item) {
            var $this = $(item);

            $this.find('input').change(function() {
                if ($('#menu-expanded-button').is(':visible')) {
                    return;
                }

                clearTimeout(triggerFormChange);
                $('.filter-page-spinner').show();
                $('.filter-objects-container').css({
                    position: 'relative',
                    opacity: '0.2',
                    filter: 'alpha(opacity=20)' /* For IE8 and earlier */
                });
                triggerFormChange = setTimeout(function() {
                    // alert('trigger');
                    // console.log('submit');
                    $this.submit();
                }, 1000);
            });
        });

        $('.filter-bar-wrapper form')
            .find('#reset-form a.cta-btn')
            .click(function() {
                event.preventDefault();
                $(this)
                    .closest('form')[0]
                    .reset();
                $('.slider').each(function() {
                    var initialMinimumValue = $(this).data('min');
                    var initialMaximumValue = $(this).data('max');
                    var unit = $(this).data('unit');
                    var $slider = $(this).find('.slider-range');

                    $slider.slider('values', 0, initialMinimumValue);
                    $slider.slider('values', 1, initialMaximumValue);
                    $(this)
                        .find('.slider-input')
                        .val(initialMinimumValue + ' ' + unit + ' - ' + initialMaximumValue + ' ' + unit);
                    $(this)
                        .find('.slider-input-start')
                        .val(initialMinimumValue);
                    $(this)
                        .find('.slider-input-end')
                        .val(initialMaximumValue);
                });
            });
    },
    initMagellan: function() {
        $('#magellan .sub-nav').css(
            'width',
            $('.sticky-anchorlink #magellan')
                .first()
                .width()
        );
    },
    initMailTipAFriend: function() {
        //$('#tip_a_friend_mail_form button').on('click', function(e){
        //
        //});
    },
    initAjaxContainers: function() {
        $('[data-ajax]').each(function() {
            var $container = $(this);

            $.post($container.data('ajax'), function(data) {
                $container.html(data);
            });
        });
    },

    initStickyHeader: function() {
        var h = document.getElementById('sticky-header');
        var p = h.parentNode;
        var stuck = false;
        var stickPoint = getDistance();
        var height = h.offsetHeight;

        p.style.paddingBottom = height + 'px';

        function getDistance() {
            var topDist = h.offsetTop + 35;

            return topDist;
        }

        function onScroll(e) {
            // var height   = h.offsetHeight;
            var distance = getDistance() - window.pageYOffset;
            var offset = window.pageYOffset;

            if (distance <= 0 && !stuck) {
                p.classList.add('stick');

                stuck = true;
            } else if (stuck && offset <= stickPoint) {
                p.classList.remove('stick');

                stuck = false;
            }
        }

        function onResize() {
            var width = window.outerWidth;
            var height = h.offsetHeight;

            if (width < 770) {
                p.style.paddingBottom = 0;
                stuck = false;
            } else {
                p.style.paddingBottom = height + 'px';
            }
        }

        $(window).on('scroll', onScroll);
        $(window).on('resize', onResize);

        onScroll();
        onResize();
    },

    /**
     * Initiate new Booking system form (november 2021)
     */
    initBookingWidget: function() {
        const containerId = 'bookingWidgetContainer';
        const $container = document.getElementById(containerId);
        if (!$container) {
            return;
        }

        const baseUrl = 'https://online.bookvisit.com/v2/widget/getwidget';
        const params = {
            channelId: 'c7068542-bc4c-49a5-9d97-7f4decef69fc',
            openOption: 'redirect',
            culture: 'sv-SE',
            layout: 'Standard1ColumnPlate',
            // layout: 'Standard1Column',
            containerId,
            displayPromoCode: false,
            displayCorpCode: false,
            displayIATACode: false,
            displayCalendarStartsAtFirstAvailableDay: true,
            lengthOfStay: '1',
            currency: 'SEK'
        };
        const urlParams = new URLSearchParams(params);
        const url = `${baseUrl}?${urlParams.toString()}`;

        (function($) {
            $.ajax({
                dataType: 'json',
                url,
                xhrFields: { withCredentials: true },
                success: function(data) {
                    $(`#${containerId}`).html(data.Widget);
                }
            });
        })(bvJQuery);
    },

    init: function() {
        $.fn.isAfter = function(sel) {
            return this.prevAll(sel).length !== 0;
        };
        $.fn.isBefore = function(sel) {
            return this.nextAll(sel).length !== 0;
        };

        App.initSelectBox();
        App.initMenu();
        //App.initCheckoutAjax();
        App.initCarousel();
        App.initReviewsCarousel();
        App.initCalendar();
        // App.initMobileShoppingCart();
        App.initMobile();
        App.initInfoBox();
        App.initSlider();
        App.initFilterForm();
        App.initMagellan();
        App.initMailTipAFriend();
        App.initAjaxContainers();
        App.initStickyHeader();
        App.initBookingWidget();
    }
};

function randomString(length) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

    if (!length) {
        length = Math.floor(Math.random() * chars.length);
    }

    var str = '';

    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
}

var AttributeSchema = function(data) {
    this.dates = [];
    this.datesFormated = {};
    //var sortedOn = false;

    //available['2015-11-25 00:00:00'] = 0;
    //available['2015-11-27 00:00:00'] = 2;
    //available['2015-11-29 00:00:00'] = 0;

    if (data) {
        for (var i in data) {
            if (data.hasOwnProperty(i)) {
                var parsed = parseDate(i, parsedDateFormat);

                this.dates.push(parsed);
                this.datesFormated[DatepickerFunctions.formatDate(parsed, parsedDateFormat)] = data[i];
            }
        }
        this.dates.sort(function(a, b) {
            return b - a;
        });
    }

    this.findClosestDate = function(date) {
        for (var d = 0; d < this.dates.length; d++) {
            if (this.dates[d] <= date) {
                return this.dates[d];
            }
        }
        return false;
    };

    this.getClosestValue = function(date) {
        var closestDate = this.findClosestDate(date);

        if (!closestDate) {
            return false;
        }
        var formatedDate = DatepickerFunctions.formatDate(closestDate, parsedDateFormat);

        if (this.datesFormated.hasOwnProperty(formatedDate)) {
            return this.datesFormated[formatedDate];
        }
        return false;
    };

    this.getHighestValuesBetween = function(start_date, end_date) {
        var valuesBetween = this.getValuesBetween(start_date, end_date);
        var highest = Number.MIN_VALUE;

        for (v = 0; v < valuesBetween.length; v++) {
            if (highest < valuesBetween[v].value) {
                highest = valuesBetween[v].value;
            }
        }
        return highest;
    };
    this.getValuesBetween = function(start_date, end_date) {
        start_date = parseDate(start_date, parsedDateFormat);
        end_date = parseDate(end_date, parsedDateFormat);

        var datesBetween = getDatesBetween(start_date, end_date);

        var valuesBetween = [];

        for (i = 0; i < datesBetween.length; i++) {
            var formatedDate = DatepickerFunctions.formatDate(datesBetween[i], parsedDateFormat);
            var closestValue = this.getClosestValue(datesBetween[i]);

            valuesBetween.push({
                date: formatedDate,
                value: closestValue
            });
        }
        return valuesBetween;
    };

    this.findAvailableDatesAround = function(date) {
        var closestBefore = false;
        var closestAfter = false;

        for (var i = 0; i < this.dates.length; i++) {
            var objectsLeft = this.datesFormated[DatepickerFunctions.formatDate(this.dates[i], parsedDateFormat)];

            if (this.dates[i] <= date) {
                if (objectsLeft < 1) {
                    break;
                }
                closestBefore = this.dates[i];
            } else if (objectsLeft < 1) {
                closestAfter = this.dates[i];
            }
        }
        return {
            before: closestBefore,
            after: closestAfter
        };
    };

    this.isDatesConnected = function(firstDate, secondDate, addDaysAfter = 0) {
        var availableAround = this.findAvailableDatesAround(firstDate);

        //console.log(availableAround,firstDate < secondDate,firstDate,secondDate);
        if (firstDate < secondDate && !availableAround.after) {
            return true;
        }
        if (firstDate > secondDate && !availableAround.before) {
            return true;
        }
        if (
            availableAround.before > secondDate ||
            (availableAround.after && secondDate >= availableAround.after.addDays(addDaysAfter))
        ) {
            return false;
        }

        return true;
    };
};

function parseDate(date, __parsedDateFormat) {
    if (!(date instanceof Date)) {
        date = DatepickerFunctions.parseDate(date, __parsedDateFormat);
    }

    return date;
}

Date.prototype.addDays = function(days) {
    var dat = new Date(this.valueOf());

    dat.setDate(dat.getDate() + days);
    return dat;
};
Date.prototype.removeDays = function(days) {
    var dat = new Date(this.valueOf());

    dat.setDate(dat.getDate() - days);
    return dat;
};

function getDatesBetween(startDate, stopDate) {
    var dateArray = [];
    var currentDate = startDate;

    while (currentDate < stopDate) {
        dateArray.push(currentDate);
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}
